<template>
  <div class="row">
    <div class="col-md-12">
      <card class="card-plain" card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Conversation List</h4>
        <el-table :data="conversations">
          <el-table-column prop="name" label="Name"></el-table-column>
          <el-table-column
            prop="messageCount"
            label="Message Count"
          ></el-table-column>
          <el-table-column
            prop="agentIntercepted"
            label="Agent Intercepted"
          ></el-table-column>
          <el-table-column min-width="100" label="Conversation Link">
            <div class="td-name" slot-scope="{ row }">
              <a :href="row.link">Open Conversation</a>
            </div>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from 'element-ui';

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  mounted() {
    this.$store.dispatch(
      'outreachConversations/loadConversations',
      this.$route.params.campaignId
    );
  },
  computed: {
    conversations() {
      return this.$store.getters['outreachConversations/conversations'];
    },
  },
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}
</style>
