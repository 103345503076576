<template>
  <div class="content">
    <div class="header text-center">
      <h3 class="title">{{ conversationTitle }}</h3>
    </div>
    <div class="row">
      <div class="col-md-12">
        <time-line>
          <time-line-item
            v-for="message in messageHistory"
            v-bind:key="message.timestamp"
            :inverted="message.role !== 'shifter'"
            :badge-type="badgeType(message)"
            :badge-icon="badgeIcon(message)"
          >
            <span slot="header" class="badge badge-pill badge-danger">{{
              message.role
            }}</span>
            <p slot="content">
              {{ message.body }}
            </p>

            <h6
              slot="footer"
              :title="convertTimestampToLocalTime(message.timestamp)"
            >
              <i class="ti-time"></i> {{ formatTimestamp(message.timestamp) }}
            </h6>
          </time-line-item>
        </time-line>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <base-textarea
          v-model="messageToSend"
          placeholder="Outbound message to shifter"
        ></base-textarea>
        <base-button color="primary" @click="sendMessage">Send</base-button>
      </div>
    </div>
  </div>
</template>
<script>
import { TimeLine, TimeLineItem } from 'src/components';
import { DateTime } from 'luxon';

export default {
  components: {
    TimeLine,
    TimeLineItem,
  },
  mounted() {
    this.$store.dispatch(
      'outreachConversations/loadConversation',
      this.$route.params.conversationId
    );
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    formatTimestamp(timestamp) {
      const date = DateTime.fromISO(timestamp);
      return date.toRelative();
    },
    convertTimestampToLocalTime(timestamp) {
      const date = DateTime.fromISO(timestamp);
      return date.toLocaleString(DateTime.DATETIME_MED);
    },
    badgeType(message) {
      switch (message.role) {
        case 'shifter':
          return 'success';
        case 'chatbot':
        case 'agent':
          return 'info';
      }
    },
    badgeIcon(message) {
      switch (message.role) {
        case 'shifter':
          return 'tim-icons icon-single-02';
        case 'chatbot':
        case 'agent':
          return 'tim-icons icon-laptop';
      }
    },
    sendMessage() {
      const conversation =
        this.$store.getters['outreachConversations/conversation'];

      if (!conversation) {
        return;
      }

      const message = {
        campaignId: conversation.campaignId,
        shifterId: conversation.shifterId,
        message: this.messageToSend,
      };

      this.$store.dispatch('outreachConversations/sendMessage', message);
      this.messageToSend = '';

      // Start polling for new messages
      this.timer = setInterval(() => {
        this.$store.dispatch(
          'outreachConversations/loadConversation',
          this.$route.params.conversationId
        );
        setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 300);
      }, 5000);
    },
  },
  computed: {
    messageHistory() {
      return this.$store.getters['outreachConversations/messageHistory'];
    },
    conversationTitle() {
      const shifterName =
        this.$store.getters['outreachConversations/shifterName'];
      if (shifterName) {
        return `Conversation with ${shifterName}`;
      }

      return 'Conversation Loading...';
    },
  },
  data() {
    return {
      messageToSend: '',
      timer: null,
    };
  },
};
</script>
<style></style>
