<template>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <outreach-events-table
          :rowData="outreachEvents"
          :columns="tableOutreach.columns"
          sendActionButton
          conversationActionButton
          @send-action="sendAction"
          @delete-action="deleteAction"
          @conversation-action="conversationAction"
          @sort-action="sortAction"
        >
        </outreach-events-table>
      </div>
    </div>
  </div>
</template>
<script>
import { OutreachEventsTable } from '@/components';
import { Select, Option } from 'element-ui';

export default {
  components: {
    OutreachEventsTable,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mounted() {
    this.$store.dispatch('outreachEvents/loadEvents');

    // Wire up event listener to format phone number.
    // const formatPhone = this.formatPhone;
    // document.getElementById('phone').addEventListener('input', function (e) {
    //   e.target.value = formatPhone(e.target.value);
    // });
  },
  computed: {
    outreachEvents() {
      return this.$store.getters['outreachEvents/outreachEvents'];
    },
    isEditing() {
      return this.$store.getters['outreachEvents/isEditing'];
    },
    jobs() {
      return this.$store.getters['outreachEvents/jobs'];
    },
  },
  methods: {
    sendAction(id) {
      this.$store.dispatch('outreachEvents/startEvent', id);
    },
    // editAction(rowData) {
    //   this.$store.dispatch('onboardingUsers/setUserIdSelection', rowData.id);
    //   this.$store.dispatch('outreachEvents/setEditing', true);

    //   this.userForm = {
    //     firstName: rowData.firstName,
    //     lastName: rowData.lastName,
    //     email: rowData.email,
    //     phone: this.formatPhone(rowData.phone),
    //     smsAllowed: rowData.smsAllowed === 'true',
    //     worksiteCode: rowData.worksiteCode,
    //   };

    //   this.modals.createEditModalVisible = true;
    // },
    deleteAction(campaignId) {
      this.$store.dispatch(
        'outreachConversations/deleteConversations',
        campaignId
      );
    },
    conversationAction(campaignId) {
      console.log('Open conversations for campaignId: ', campaignId);
      window.open(`/conversations/${campaignId}`);
    },
    sortAction(prop, order) {
      // this.$store.dispatch('outreachEvents/setSortData', { prop, order });
      // this.$store.dispatch('outreachEvents/sortTable');
    },
  },
  data() {
    return {
      tableOutreach: {
        title: 'Events',
        columns: [
          { name: 'Title', minWidth: 150 },
          { name: 'Job Name', minWidth: 150 },
          { name: 'Shifter Count' },
          { name: 'Status' },
          //{ name: 'Phone', formatter: this.formatPhoneColumn },
        ],
      },
      typeOptions: [
        {
          value: 'sms',
          label: 'SMS',
        },
        {
          value: 'email',
          label: 'E-mail',
        },
      ],
      eventForm: {
        title: '',
        jobId: '',
        jobName: '',
        range: 0,
        shifters: [],
      },
    };
  },
};
</script>
<style></style>
